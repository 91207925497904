<template>
	<v-text-field
		:id="id"
		ref="numberInput"
		:label="label"
		:loading="loading"
		:disabled="disabled"
		:hide-details="hideDetails"
		outlined
		:rules="rules"
		type="number"
		class="pt-0"
		:class="{ ...customClass, 'mt-3': !hideTopMargin }"
		v-model="numberinput"
		:suffix="suffix"
		:append-outer-icon="appendOuterIcon"
		:placeholder="placeholder"
		v-on:click:append-outer="$emit('click:append-outer', true)"
		v-on:onchange="$emit('onchange', true)"
		v-on:keyup="$emit('keyup', true)"
	></v-text-field>
</template>
<script>
import { toSafeInteger } from "lodash";

export default {
	name: "number-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		customClass: {
			type: String,
			default: "",
		},
		value: {
			type: [String, Number],
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		placeholder: {
			type: String,
			default: null,
		},
		suffix: {
			type: String,
			default: null,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			numberinput: null,
		};
	},
	watch: {
		value(param) {
			this.numberinput = toSafeInteger(param);
		},
		numberinput() {
			console.log({ placeholder: this.placeholder, numberinput: this.numberinput, value: this.value });
			this.$emit("input", toSafeInteger(this.numberinput));
			this.$emit("change", toSafeInteger(this.numberinput));
		},
	},
	mounted() {
		this.numberinput = toSafeInteger(this.value);
	},
};
</script>
